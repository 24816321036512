/*
Template Name: Shreyu - Responsive Bootstrap 5 Admin Dashboard
Author: CoderThemes
Version: 2.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "../../../node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";
@import "custom/structure/horizontal-nav";
@import "custom/structure/two-column-menu";

//Components
@import "custom/components/avatar";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/email";
@import "custom/pages/pricing";
@import "custom/pages/profile";
@import "custom/pages/task";
@import "custom/pages/timeline";
@import "custom/pages/kanbanboard";
@import "custom/pages/landing";
@import "custom/pages/file-manager.scss";
@import "custom/pages/chat";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/colorpicker";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker"; // react-datepicker style
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/multiple-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/react-select"; // react-select style
@import 'custom/plugins/react-table'; // react table style
@import "custom/plugins/flatpickr";
@import "custom/plugins/dropzone";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";

body {
  position: relative;
}

.import-question input {
  display: none;

}

.import-question .input-group-text {
  cursor: pointer;
  background-color: rgba(37, 194, 227, 0.15);
  color: #25c2e3;
  border-color: rgba(37, 194, 227, 0.15);
}

.coin_history_list {
  align-items: center;

  li {
    list-style: none;
    padding: 0px 8px;

  }

}

.spinner-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.spinner-wrapper::after {
  content: "";
background-color: #25c2e3;
}

.react-select-container.is-invalid{
  border: 1px solid #ff5c75;
  border-radius: 4px;
}
.react-select-container.is-invalid .css-1s2u09g-control{
  border: none !important;
}

.column-width{
  min-width: 200px;
  max-width: 200px;
  width: 200px;
  white-space: normal !important;
}

.react-datepicker__time-container{
  width: 120px !important;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{
  width: 120px !important;
}